import { useEffect, useRef, useState } from "react";
import '../css/rotatingImages.css'



const RotatingImages = () => {
    const imagesArray = [
        '/rotatingImages/rotate1.jpg',
        '/rotatingImages/rotate2.jpg',
        '/rotatingImages/rotate3.jpg',
        '/rotatingImages/rotate4.jpg'
    ]

    const intervalRef = useRef(null)
    const [currentImage, setCurrentImage] = useState(0)
    
    useEffect(()=>{
        intervalRef.current = setInterval(()=>{
            setCurrentImage(prev=>prev+1)
        },5000)
        
        return ()=> clearInterval(intervalRef.current)
    }, [])

    useEffect(()=>{
        if(currentImage===3){
            clearInterval(intervalRef.current)
        }
    }, [currentImage])

    const handleClick = (img)=>{
        setCurrentImage(img)
        clearInterval(intervalRef.current)
    }

    return ( 
        <div className="rotatingImages">
            <div className="images" style={{right:`${currentImage*100}%`}}>
                <div className="image" style={{backgroundImage:`url(${imagesArray[0]})`}}></div>
                <div className="image" style={{backgroundImage:`url(${imagesArray[1]})`}}></div>
                <div className="image" style={{backgroundImage:`url(${imagesArray[2]})`}}></div>
                <div className="image" style={{backgroundImage:`url(${imagesArray[3]})`}}></div>
            </div>
            
    
            <div className="imageScroll">
                <div className="imageScrollItem" style={{backgroundColor:`${currentImage===0?'black':'rgb(100, 100, 100)'}`}} onClick={()=>handleClick(0)}></div>
                <div className="imageScrollItem" style={{backgroundColor:`${currentImage===1?'black':'rgb(100, 100, 100)'}`}} onClick={()=>handleClick(1)}></div>
                <div className="imageScrollItem" style={{backgroundColor:`${currentImage===2?'black':'rgb(100, 100, 100)'}`}} onClick={()=>handleClick(2)}></div>
                <div className="imageScrollItem" style={{backgroundColor:`${currentImage===3?'black':'rgb(100, 100, 100)'}`}} onClick={()=>handleClick(3)}></div>
            </div>
        </div>
     );
}
 
export default RotatingImages;