import '../css/about.css'
import Footer from "../components/Footer";
const About = () => {
    return ( 
        <div className="about">
            <h1>About Us</h1>
            <div className="aboutImage"></div>
            <div className="aboutInfo">
                <h1>About Our Painting Company</h1>
                <p>Welcome to our painting company, founded by Ferdinand and Luka in Calgary. We are passionate about transforming spaces with creativity and precision.</p>
                
                <h2>Our Story</h2>
                <p>Ferdinand and Luka, two young entrepreneurs with a shared vision for excellence, established our company with a mission to provide exceptional painting services in Calgary and beyond. Our journey began with a commitment to quality craftsmanship and customer satisfaction.</p>
                
                <h2>Our Mission</h2>
                <p>At our painting company, we strive to deliver superior painting solutions tailored to meet the unique needs of each client. Whether it's residential or commercial projects, we approach every job with dedication and expertise.</p>
                
                <h2>Why Choose Us?</h2>
                <ul>
                    <li>Experienced and skilled painters</li>
                    <li>Attention to detail</li>
                    <li>Use of high-quality materials</li>
                    <li>Commitment to customer satisfaction</li>
                    <li>Competitive pricing</li>
                </ul>
                
                <h2>Get In Touch</h2>
                <p>Ready to transform your space? Contact us today for a consultation and let's bring your vision to life with our professional painting services.</p>
                
                <p>Phone: +1 (403) 408 7285</p>
                <p>Email: painting@cudiest.com</p>
                <p>Owner and CEO: Ferdinand Nodilo</p>
            </div>
            <Footer/>
        </div>
     );
}
 
export default About;