import { useRef, useState } from 'react';
import '../css/quote.css'

const Quote = () => {
    const [quoteClass,setQuoteClass] = useState('quote-form');
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [description, setDescription] = useState('')
    const closeButton = useRef(null)

    const handleClick = ()=>{
        setQuoteClass('quote-form active')
    }

    const handlesubmit = async (e)=>{
        e.preventDefault()
        const order = {firstName,lastName,email,phone,description}
        const response = await fetch('/api/clients/create', {
          method:'POST',
          body:JSON.stringify(order),
          headers:{'Content-Type':'application/json'}
        })
  
        const json = await response.json()
        if(response.ok){
          if(json.result!==0){
            alert(json.error)
          }else{
            alert('Your order has been received. We will contact you shortly.')
            closeButton.current.click()
          }
        }
    }

    const onClose = ()=>{
        setQuoteClass('quote-form')
    }
    return ( 
        <div>
            <div className="quote">
                <h1>PREMIUM PAINTING WITHOUT THE PREMIUM PRICE</h1>
                <button onClick={handleClick}>Get a Qoute</button>
            </div>
            <form onSubmit={handlesubmit} className={quoteClass}>
                <div className="title">
                    <span>Get a Quote</span>
                    <span ref={closeButton} className="close-button" onClick={onClose}>X</span>
                </div>
                <div className="names">
                    <div className="form-group">
                        <label htmlFor="first-name">First Name:</label>
                        <input value={firstName} onChange={(e)=>setFirstName(e.target.value)} type="text" id="first-name" name="first-name" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="last-name">Last Name:</label>
                        <input value={lastName} onChange={(e)=>setLastName(e.target.value)} type="text" id="last-name" name="last-name" required />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email Address:</label>
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" id="email" name="email" required />
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Phone:</label>
                    <input value={phone} onChange={(e)=>setPhone(e.target.value)} type="tel" id="phone" name="phone" required />
                </div>
                <div className="form-group">
                    <label htmlFor="job-description">Job Description:</label>
                    <textarea value={description} onChange={(e)=>setDescription(e.target.value)} id="job-description" name="job-description" required></textarea>
                </div>
                <button type="submit">Submit</button>
            </form>
        </div>
     );
}
 
export default Quote;