import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation, Scrollbar, A11y, EffectCube } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import '../css/gallery.css'
import { useRef, useState } from "react";





const SwiperComponent = ({images}) => {
    const swiperRef = useRef(null)
    const [swiperBackgroundStyle, setSwiperBackgroundStyle] = useState("swiperBackground")
    const [swiperTop, setSwiperTop] = useState(0)
    const exitSwiper = ()=>{
        setSwiperBackgroundStyle("swiperBackground")
    }

    const [currentAlt, setCurrentAlt] = useState(images[0].alt)

    const openImage1 = (imgKey,e)=>{
        setSwiperBackgroundStyle("swiperBackground visible")
        swiperRef.current.swiper.slideTo(imgKey-1);
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        setSwiperTop(scrollTop)
    }
    const hideSwiper = (e)=>{
        if(e.target.className === 'swiperBackground visible'){
            setSwiperBackgroundStyle("swiperBackground")
        }
    }

    const handleSlideChange = () => {
        const swiperInstance = swiperRef.current.swiper;
        const currentIndex = swiperInstance.activeIndex;
        setCurrentAlt(images[currentIndex].alt);
      };
    return ( 
        <div className="gallery">
            <div className={swiperBackgroundStyle} style={{top:swiperTop}} onClick={hideSwiper}>
                <div className="swiperInfo">
                    <div className="imageAlt">{currentAlt}</div>
                    <div className="exit" onClick={exitSwiper}>X</div>
                </div>
                <Swiper ref={swiperRef}  className="swiper"
                    modules={[Navigation, Scrollbar, A11y, EffectCube]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    effect={"cube"}
                    cubeEffect={{
                    shadow: true,
                    slideShadows: true,
                    shadowOffset: 20,
                    shadowScale: 0.94,
                    }}
                    onSlideChange={handleSlideChange}
                >
                    {images.map((image)=>
                        (
                            <SwiperSlide className="swiperSlide" key={image.key} >
                                    <img src={image.url} alt={image.alt}/>
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            </div>
          <div className="images">
                {images.map((image)=>(
                    <div className="box" key={image.key} onClick={(e)=>openImage1(image.key,e)}>
                        <img src={image.url} alt={image.alt} />
                        <div className="dark"></div>
                    </div>
                ))}
          </div>
        </div>
     );
}
 
export default SwiperComponent;