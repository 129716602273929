import { useState } from 'react';
import '../css/slider.css'

const Slider = () => {
    const [currentLeft, setCurrentLeft] = useState(100)
    const [currentLeft1, setCurrentLeft1] = useState(100)
    const handleChange = (e)=>{
        setCurrentLeft(e.target.value)
    }
    const handleChange1 = (e)=>{
        setCurrentLeft1(e.target.value)
    }
    return ( 
        <div className="slider">
            <h1>Interior and Exterior painting services</h1>
            <h2>interior</h2>
            <div className="sliderItem">
                <div className="sliderItemImages">
                    <div className="imageOne"></div>
                    <div className="imageTwo" style={{left:`${currentLeft}%`, backgroundPosition:`${-currentLeft}%`}}></div>
                    <input type="range" min={0} max={100} onChange={handleChange} value={currentLeft}/>
                </div>
                <p className="sliderItemParagraph">At Cudiest Painters, we understand that your property’s interior is as crucial as its exterior. That's why we specialize in delivering premium interior painting services tailored to your specific needs. Our experienced team will collaborate with you to choose the perfect colors and finishes that breathe new life into your space. We pride ourselves on completing projects efficiently and within budget, ensuring minimal disruption to your daily routine. Whether you want to refresh a single room or revamp your entire property, we’re here to help make your vision a reality. Additionally, we use eco-friendly paints to ensure a safer environment for you and your family. Trust Cudiest Painters to provide exceptional interior painting services that exceed your expectations.</p>
            </div>
            <h2>exterior</h2>
            <div className="sliderItem">
                <p className="sliderItemParagraph">Painting your home's exterior is a quick and effective way to enhance its curb appeal and increase its value, especially if you're selling. However, exterior painting can be challenging without experience. Cudiest offers unmatched exterior painting services, providing quality results and ongoing support from expert painters. Choosing the right exterior paint colors involves considering your landscape, architecture, and existing features like the roof and driveway. Our Cudiest Color Consultants will help you make the best choices to complement your environment and highlight your home's unique details. Trust Cudiest Painters for exceptional results inside and out.</p>
                <div className="sliderItemImages">
                    <div className="imageOne"></div>
                    <div className="imageTwo" style={{left:`${currentLeft1}%`, backgroundPosition:`${-currentLeft1}%`}}></div>
                    <input type="range" min={0} max={100} onChange={handleChange1} value={currentLeft1}/>
                </div>
                
            </div>
        </div>
     );
}
 
export default Slider;