import { useState } from 'react';
import Footer from "../components/Footer";
import '../css/quote.css'
import '../css/contact.css'

const Contact = () => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [description, setDescription] = useState('')



    const handlesubmit = async (e)=>{
        e.preventDefault()
        const order = {firstName,lastName,email,phone,description}
        const response = await fetch('/api/clients/create', {
          method:'POST',
          body:JSON.stringify(order),
          headers:{'Content-Type':'application/json'}
        })
  
        const json = await response.json()
        if(response.ok){
          if(json.result!==0){
            alert(json.error)
          }else{
            alert('Your order has been received. We will contact you shortly.')
          }
        }
    }

    return ( 
        <div className='contact'>
            <h1>Get a Free Estimate</h1>
            <div className="flexContainer">
                <form onSubmit={handlesubmit} className='quote-form active'>
                    <div className="title">
                        <span>Get a Quote</span>
                    </div>
                    <div className="names">
                        <div className="form-group">
                            <label htmlFor="first-name">First Name:</label>
                            <input value={firstName} onChange={(e)=>setFirstName(e.target.value)} type="text" id="first-name" name="first-name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="last-name">Last Name:</label>
                            <input value={lastName} onChange={(e)=>setLastName(e.target.value)} type="text" id="last-name" name="last-name" required />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email Address:</label>
                        <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" id="email" name="email" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Phone:</label>
                        <input value={phone} onChange={(e)=>setPhone(e.target.value)} type="tel" id="phone" name="phone" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="job-description">Job Description:</label>
                        <textarea value={description} onChange={(e)=>setDescription(e.target.value)} id="job-description" name="job-description" required></textarea>
                    </div>
                    <button type="submit">Submit</button>
                </form>
                <div className="contactInfo">
                    <p>CUDIEST INC.</p>
                    <p>223 Huntington Park Bay, NW, Calgary, Alberta, T2K5H5</p>
                    <p>painting@cudiest.com</p>
                    <div className="phone">
                        <span>Call us:</span><a href="tel:+1(403)4087285"> +1 (403) 408 7285</a>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
     );
}
 
export default Contact;