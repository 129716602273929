import '../css/footer.css'

const Footer = () => {
    return ( 
        <div className="footer">
            <div className="summary">
                <p>At Cudiest, our painting company is driven by a commitment to excellence and customer satisfaction. With a decade of experience, our skilled and professional team is passionate about delivering top-quality painting services. We aim to create beautiful, lasting results while adhering to reasonable budgets and realistic timelines. Customer satisfaction is our number one priority!</p>
                <p>At Cudiest, we specialize in a wide range of painting services, from transforming condo and brownstone common area lobbies to enhancing high-end kitchens and bathrooms, as well as exterior facades and decking systems. Our team's dedication and expertise ensure we can create beautiful, lasting finishes that fit your lifestyle and budget. Trust Cudiest Painters to bring your vision to life with exceptional quality and service.</p>
            </div>
            <div className="bottom">
                <p>CUDIEST INC.</p>
                <p>223 Huntington Park Bay, NW, Calgary, Alberta, T2K5H5</p>
                <p>painting@cudiest.com</p>
            </div>
        </div>
     );
}
 
export default Footer;