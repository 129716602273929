import Navigation from "../components/Navigation";
import Quote from "../components/Quote";
import Footer from "../components/Footer";
import RotatingImages from "../components/RotatingImages";
import Slider from "../components/Slider";
import '../css/main.css'

const Main = () => {
    return ( 
        <div className="main">
            <RotatingImages/>
            <Quote/>
            <Slider/>
            <Footer/>
        </div>
     );
}
 
export default Main;