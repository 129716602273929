import { useState } from 'react'
import '../css/navigation.css'

const Navigation = () => {
    const [hamburgerClasses, setHamburgerClasses] = useState('hamburger')
    const [navClasses, setNavClasses] = useState('nav')
    const handleClick = (e)=>{
        setHamburgerClasses(prev => prev==='hamburger'?'clicked hamburger':'hamburger')
        setNavClasses(prev => prev==='nav'?'visib nav':'nav')
    }
    return ( 
        <header className="navigation">
            <a className="home" href='/'>
                <div className="image"></div>
                <span>Home</span>
            </a>
            <a className='number' href="tel:+1(403)4087285"><img src="/phone.svg" alt="phone" /><span>+1 (403) 408 7285</span></a>
            <nav className={navClasses}>
                <a href="/about" className="navItem">About us</a>
                <a href="/contact" className="navItem">Contact us</a>
                <a href="/gallery" className="navItem">Project gallery</a>
            </nav>
          
            <div className={hamburgerClasses} onClick={handleClick}>
                <div className="hamb-item"></div>
                <div className="hamb-item"></div>
                <div className="hamb-item"></div>
            </div>
         
        </header>
     );
}
 
export default Navigation;