import { BrowserRouter as Router, Switch, Route } from "react-router-dom/cjs/react-router-dom.min";
import Main from "./pages/Main";
import About from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Navigation from "./components/Navigation";

function App() {
  return (
    <Router>
    <div className="App">
    <Navigation/>
      <Switch>
        <Route exact path='/'>
          <Main/>
        </Route>
        <Route exact path='/about'>
          <About/>
        </Route>
        <Route exact path='/contact'>
          <Contact/>
        </Route>
        <Route exact path='/gallery'>
          <Gallery/>
        </Route>
      </Switch>
    </div>
    </Router>
  );
}

export default App;
