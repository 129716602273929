import 'swiper/swiper-bundle.min.css';
import '../css/gallery.css'
import images1 from '../GalleryImages1.json'
import images2 from '../GalleryImages2.json'
import SwiperComponent from "../components/SwiperComponent";
import Footer from "../components/Footer";



const Gallery = () => {
    return ( 
        <div className="gallery">
            <div className="headingImage"></div>
            <h1>Interior Painting</h1>
            <SwiperComponent images={images1}/>
            <h1>Exterior Painting</h1>
            <SwiperComponent images={images2}/>
            <Footer/>
        </div>
     );
}
 
export default Gallery;